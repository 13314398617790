import { match as batchType } from "../../../src/params/batchType.ts";
import { match as chatId } from "../../../src/params/chatId.ts";
import { match as filteredForum } from "../../../src/params/filteredForum.ts";
import { match as int } from "../../../src/params/int.ts";
import { match as iso } from "../../../src/params/iso.ts";
import { match as languageLevel } from "../../../src/params/languageLevel.ts";
import { match as listen } from "../../../src/params/listen.ts";
import { match as taskType } from "../../../src/params/taskType.ts";
import { match as weGroup } from "../../../src/params/weGroup.ts";

export const matchers = { batchType, chatId, filteredForum, int, iso, languageLevel, listen, taskType, weGroup };