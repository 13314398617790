export function match(value: string) {
  // select group_concat(iso) from languages;
  return [
    'eng',
    'srp',
    'ceb',
    'tgl',
    'spa',
    'cmn',
    'jpn',
    'kor',
    'deu',
    'fra',
    'ita',
    'rus',
    'arb',
    'ben',
    'hin',
    'ell',
    'por',
    'vie',
    'tur',
    'ind',
    'pol',
    'bos',
    'hrv',
    'bul',
    'hun',
    'fin',
    'ron',
    'jav',
    'tel',
    'mar',
    'tam',
    'pnb',
    'guj',
    'pes',
    'bho',
    'kan',
    'sun',
    'mal',
    'ory',
    'mya',
    'ukr',
    'pan',
    'amh',
    'yor',
    'tha',
    'uzn',
    'nld',
    'sin',
    'swh',
    'khm',
    'npi',
    'zul',
    'ces',
    'urd',
    'run',
    'swe',
    'xho',
    'prs',
    'plt',
    'hat',
    'afr',
    'kin',
    'som',
    'azj',
    'pbt',
    'dan',
    'kaz',
    'heb',
    'slk',
    'gug',
    'nor',
    'nso',
    'kat',
    'tuk',
    'tgk',
    'hye',
    'lao',
    'lit',
    'kir',
    'khk',
    'tso',
    'bel',
    'slv',
    'mkd',
    'mlt',
    'isl',
    'mai',
    'hau',
    'snd',
    'ibo',
    'asm',
    'gaz',
    'uig',
    'lua',
    'tir',
    'tat',
    'bem',
    'wuu',
    'yue',
    'cjy',
    'hsn',
    'hak',
    'nan',
    'gan',
    'mnp',
    'cdo',
    'cat',
    'ckb',
    'mvf',
    'kng',
    'uzs',
    'fuf',
    'knn',
    'glg',
    'lin',
    'aln',
    'mni',
    'che',
    'toi',
    'aar',
    'bod',
    'arz',
    'arq',
    'ary',
    'apd',
    'acm',
    'aeb',
    'apc',
    'ars',
    'ayl',
    'ajp',
    'hil',
    'ilo',
    'war',
    'bcl',
    'gsw',
    'ekk',
    'lvs',
    'nya',
    'pap',
    'pcm',
    'sna',
    'hmn',
    'zsm'
  ].includes(value)
}
