export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71')
];

export const server_loads = [0,2,3];

export const dictionary = {
		"/": [~4],
		"/(static)/about-us": [9,[2]],
		"/auth/forgot-password": [60,[3]],
		"/auth/login": [61,[3]],
		"/auth/register": [62,[3]],
		"/auth/verify": [63,[3]],
		"/(static)/contact-us": [10,[2]],
		"/(static)/cookie-policy": [11,[2]],
		"/me": [64],
		"/me/activities": [65],
		"/me/edit-profile": [66],
		"/me/settings": [67],
		"/me/stats": [68],
		"/(nonLang)/missions/[[id=int]]": [5],
		"/(nonLang)/plans": [6],
		"/(static)/privacy-policy": [12,[2]],
		"/(nonLang)/reset": [7],
		"/(nonLang)/social": [8],
		"/(static)/terms": [13,[2]],
		"/users/ranking": [70],
		"/users/search": [71],
		"/users/[id=int]": [69],
		"/[iso=iso]": [~14],
		"/[iso=iso]/advanced/bulk-add-words": [15],
		"/[iso=iso]/advanced/import": [16],
		"/[iso=iso]/advanced/import/[id]": [17],
		"/[iso=iso]/advanced/language-scripts": [18],
		"/[iso=iso]/advanced/learn-script/[id]": [19],
		"/[iso=iso]/advanced/practice-script/[id]": [20],
		"/[iso=iso]/advanced/translate-script/[id]": [21],
		"/[iso=iso]/advanced/vocabulary-compare": [22],
		"/[iso=iso]/advanced/word-props-patterns": [24],
		"/[iso=iso]/advanced/word-props": [23],
		"/[iso=iso]/chat": [25],
		"/[iso=iso]/chat/users": [27],
		"/[iso=iso]/chat/[id=chatId]": [26],
		"/[iso=iso]/forum": [~28],
		"/[iso=iso]/forum/categories": [~31],
		"/[iso=iso]/forum/edit-reply/[topicId=int]/[replyId=int]/[[language]]": [33],
		"/[iso=iso]/forum/edit/[topicId=int]/[[language]]": [32],
		"/[iso=iso]/forum/translate/[topicId=int]/[[replyId=int]]": [34],
		"/[iso=iso]/forum/[group=filteredForum]": [30],
		"/[iso=iso]/forum/[...slug]": [~29],
		"/[iso=iso]/goals": [35],
		"/[iso=iso]/goals/[id=int]": [36],
		"/[iso=iso]/guides": [37],
		"/[iso=iso]/home/[...slug]": [38],
		"/[iso=iso]/lessons": [39],
		"/[iso=iso]/lessons/[level=languageLevel]": [40],
		"/[iso=iso]/lessons/[level=languageLevel]/[lessonId=int]": [41],
		"/[iso=iso]/listen": [42],
		"/[iso=iso]/listen/sentences": [43],
		"/[iso=iso]/listen/words": [44],
		"/[iso=iso]/reading/learn": [45],
		"/[iso=iso]/reading/progress": [46],
		"/[iso=iso]/stats/leveled": [47],
		"/[iso=iso]/task": [48],
		"/[iso=iso]/task/[type=taskType]/correct/[id=int]": [49],
		"/[iso=iso]/task/[type=taskType]/create/[[id]]": [50],
		"/[iso=iso]/task/[type=taskType]/find/[[id=int]]": [51],
		"/[iso=iso]/task/[type=taskType]/select/[[id]]/[[target]]": [52],
		"/[iso=iso]/tests": [53],
		"/[iso=iso]/tests/qualification": [55],
		"/[iso=iso]/tests/qualification/refresh": [56],
		"/[iso=iso]/tests/[batchType=batchType]/[[refresh]]": [54],
		"/[iso=iso]/vocabulary": [57],
		"/[iso=iso]/vocabulary/[group=weGroup]": [58],
		"/[iso=iso]/word-forms": [59]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';